// Overwrite css

// CARD
.card-box {
  .no-radius {
    border-radius: 0px;
  }
}

//Date picker
.MuiPickersCalendarHeader-switchHeader {
  margin: 5px;
}

.border-bottom-radius {
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
}

.border-top-radius {
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
}

//bkg
.background-gray-light {
  background: #fcfcfc;
}
